import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";

const training = new Swiper('.training-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 300,
  autoplay: {
    delay: 3000,
    pauseOnMouseEnter: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const achievements = new Swiper('.achievements-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 300,
  autoplay: {
    delay: 3000,
    pauseOnMouseEnter: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const children = new Swiper('.children-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 300,
  autoplay: {
    delay: 3000,
    pauseOnMouseEnter: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const modeling = new Swiper('.modeling-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const origami = new Swiper('.origami-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const creatures = new Swiper('.creatures-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const nature = new Swiper('.nature-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const bookGraphics = new Swiper('.book-graphics-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const art = new Swiper('.art-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const experiments = new Swiper('.experiments-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const stories = new Swiper('.stories-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const mnemonicTable = new Swiper('.mnemonic-table-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const emotions = new Swiper('.emotions-swiper', {
  modules: [ Autoplay, Pagination, Navigation ],
  slidesPerView: 'auto',
  spaceBetween: 24,
  speed: 300,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});